import NeuralXLoading from "components/NeuralXLoading";
import React, { Suspense, lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import STORAGE_NAMES from "utils/constants/storage-names";
import { getItemFromStorage } from "utils/helpers/storage";
import PAGES from "utils/links/pages";
import { validateMinimumTier } from "utils/helpers/common";
import PAGE_NAMES from "utils/constants/page-names";

const Layout = lazy(() => import(/* webpackChunkName: "layout" */ "layout"));
const Home = lazy(() => import(/* webpackChunkName: "home" */ "pages/MainDashboardPage"));
const ClassDetailPage = lazy(() => import(/* webpackChunkName: "class-detail-page" */ "pages/ClassDetailPage"));
const ClipLibraryPage = lazy(() => import(/* webpackChunkName: "clip-library-page" */ "pages/ClipLibraryPage"));
const LiveSessionPage = lazy(() => import(/* webpackChunkName: "live-session-page" */ "pages/LiveSessionPage"));
const ProfilePage = lazy(() => import(/* webpackChunkName: "profile-page" */ "pages/ProfilePage"));
const NewClassPage = lazy(() => import(/* webpackChunkName: "new-class-page" */ "pages/NewClassPage"));
const MyClassesPage = lazy(() => import(/* webpackChunkName: "my-classes-page" */ "pages/MyClassesPage"));
const CalendarPage = lazy(() => import(/* webpackChunkName: "calendar-page" */ "pages/CalendarPage"));
const CustomerLivePage = lazy(() => import(/* webpackChunkName: "customer-live-page" */ "pages/CustomerLivePage"));


const PageRouters = () => {
    const loggedIn = getItemFromStorage({ storageName: STORAGE_NAMES.SESSION, name: "isLoggedIn" });

    return (
        loggedIn ? (
            <Layout>
                <Suspense fallback={<NeuralXLoading wholeOverlay />}>
                    <Route
                        exact
                        path={PAGES.HOME}
                        render={props => <Home {...props} />} />
                    <Route
                        exact
                        path={PAGES.CLASS_DETIAL}
                        render={props => (
                            validateMinimumTier(PAGE_NAMES.CLASS_DETAIL.tier) ? (
                                <ClassDetailPage {...props} />
                            ) : (
                                <Redirect to={{ pathname: PAGES.HOME }} />
                            )
                        )} />
                    <Route
                        exact
                        path={PAGES.CALENDAR}
                        render={props => <CalendarPage {...props} />} />
                     <Route
                        exact
                        path={PAGES.CUSTOMER_LIVE}
                        render={props => <CustomerLivePage {...props} />} />
                    <Route
                        exact
                        path={PAGES.CLIPS}
                        render={props => (
                            validateMinimumTier(PAGE_NAMES.CLIPS.tier) ? (
                                <ClipLibraryPage {...props} />
                            ) : (
                                <Redirect to={{ pathname: PAGES.HOME }} />
                            )
                        )} />
                    <Route
                        exact
                        path={PAGES.NEW_CLASS}
                        render={props => (
                            validateMinimumTier(PAGE_NAMES.NEW_CLASS.tier) ? (
                                <NewClassPage {...props} />
                            ) : (
                                <Redirect to={{ pathname: PAGES.HOME }} />
                            )
                        )} />
                    <Route
                        exact
                        path={PAGES.LIVE}
                        render={props => (
                            validateMinimumTier(PAGE_NAMES.LIVE.tier) ? (
                                <LiveSessionPage {...props} />
                            ) : (
                                <Redirect to={{ pathname: PAGES.HOME }} />
                            )
                        )} />
                    <Route
                        exact
                        path={PAGES.MY_CLASSES}
                        render={props => (
                            validateMinimumTier(PAGE_NAMES.MY_CLASSES.tier) ? (
                                <MyClassesPage {...props} />
                            ) : (
                                <Redirect to={{ pathname: PAGES.HOME }} />
                            )
                        )} />
                    <Route
                        exact
                        path={PAGES.PROFILE}
                        render={props => <ProfilePage {...props} />} />
                </Suspense>
            </Layout>
        ) : (
            <Redirect to={{ pathname: PAGES.LOGIN }} />
        )
    );
};

export default PageRouters;
