import PAGES from "utils/links/pages";
import { TIERS } from "./commons";

const PAGE_NAMES = {
    MAIN: {
        id: "main-dashboard",
        name: "HOME",
        link: PAGES.HOME,
        tier: TIERS.GROUP
    },
    CLIPS: {
        id: "clips",
        name: "CLIPS",
        link: PAGES.CLIPS,
        tier: TIERS.ADMIN
    },
    NEW_CLASS: {
        id: "new-class",
        name: "NEW CLASS",
        link: PAGES.NEW_CLASS,
        tier: TIERS.PERSONAL
    },
    MY_CLASSES: {
        id: "my-classes",
        name: "MY CLASSES",
        link: PAGES.MY_CLASSES,
        tier: TIERS.GROUP
    },
    CLASS_DETAIL: {
        id: "class-detail",
        name: "CLASS DETAIL",
        link: PAGES.CLASS_DETIAL,
        tier: TIERS.GROUP
    },
    LIVE: {
        id: "live-session",
        name: "LIVE SESSION",
        link: PAGES.LIVE,
        tier: TIERS.GROUP
    },
    PROFILE: {
        id: "profile",
        name: "PROFILE",
        link: PAGES.PROFILE,
        tier: TIERS.GROUP
    },
    CALENDAR: {
        id: "calendar",
        name: "CALENDAR",
        link: PAGES.CALENDAR,
        tier: TIERS.PERSONAL
    },
    CUSTOMER_LIVE: {
        id: "customer-live",
        name: "CUSTOMER LIVE",
        link: PAGES.CUSTOMER_LIVE,
        tier: TIERS.PERSONAL
    }
};

export default PAGE_NAMES;
