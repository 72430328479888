import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Offline as OfflineIntegration } from "@sentry/integrations";

import client from "apollo/lib";
import { APP_BUIDS } from "utils/constants/commons";
import App from "./App";
import "./index.css";

window.build = process.env.REACT_APP_BUILD || APP_BUIDS.LOCAL;
window.defaultImage = "imgs/placeholder.gif";

Sentry.init({
    dsn: "https://11f9b96bf06b41c5bb2b42dd39171b04@o509016.ingest.sentry.io/5602594",
    integrations: [new OfflineIntegration(
        {
            // limit how many events will be localled saved. Defaults to 30.
            maxStoredEvents: 50
        }
    )]
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>,
    document.getElementById("root")
);
